import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ApiCall, ApiKey } from '../../common/Apikey';
import { ThreeDots, Watch } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import travel from '../../assets/image/travel.svg'
import route from '../../assets/image/route.svg'
import airport from '../../assets/image/airport.svg'
import aeroplane from '../../assets/image/aeroplane-landing-2.svg'
import two_arrow from '../../assets/image/two_arrow.svg'
import calendar from '../../assets/image/calendar.png'
import sedan from '../../assets/image/sedan.png'
import clock from '../../assets/image/clock.png'
import logo_big_new from '../../assets/image/logo_big_new.jpeg'
import alertImg from '../../assets/image/alert.png'

import { Autocomplete, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import moment from 'moment';
import $ from 'jquery'

import Paynow from '../Paynow'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardModule from '../CardModule'
import EditPaynow from './EditPaynow';
import { Tooltip as ReactTooltip } from "react-tooltip";
import EditAddonModel from './EditAddonModel';

function EditBooking() {
  let navigate = useNavigate();
  const stripePromise = loadStripe(ApiKey.stripekey);

  Geocode.setApiKey(ApiKey.google_map_key);
  Geocode.setLanguage("en");
  const [map_key, setMapKey] = useState(ApiKey.google_map_key)
  const [libraries, setLibraries] = useState(["places"])

  const [PayNowStatus, setPayNowStatus] = useState(0)

  const AutocompleteRestrict = {
    types: ['establishment'],
    componentRestrictions: { country: ["sg", "my"] },
  };

  let timeConstraints = {
    minutes: {
      step: 5
    }
  }

  const [initial_state, setInitialState] = useState(0)
  const [countries, setCountries] = useState([])
  const [data, setData] = useState([])
  const [currency, setCurrency] = useState([])
  const [editLoader, seteditLoader] = useState(0)

  // from location
  const [from_places, setFromPlaces] = useState("")
  const [EditbookPick, setEditbookPick] = useState("")
  const [edit_pick_lat, setedit_pick_lat] = useState("")
  const [edit_pick_lon, setedit_pick_lon] = useState("")

  // to location
  const [to_places, setToPlaces] = useState("")
  const [EditbookDrop, setEditbookDrop] = useState("")
  const [edit_drop_lat, setedit_drop_lat] = useState("")
  const [edit_drop_lon, setedit_drop_lon] = useState("")

  // edit booking data
  const [EditbookDate, setEditbookDate] = useState("")
  const [EditbookTime, setEditbookTime] = useState("")
  const [edit_service_type, setedit_service_type] = useState("")
  const [edit_vehicle_id, setedit_vehicle_id] = useState()
  const [edit_rider_type, setedit_rider_type] = useState("3")
  const [edit_vehicle_type, setedit_vehicle_type] = useState("")
  const [edit_customer_id, setedit_customer_id] = useState("")
  const [edit_trip_type, setedit_trip_type] = useState('guest')
  const [booking_id, setbooking_id] = useState("")
  const [edit_flight_actual_arrival_time, setedit_flight_actual_arrival_time] = useState("")
  const [edit_flight_eta, setedit_flight_eta] = useState("")
  const [edit_flight_carrier_code, setedit_flight_carrier_code] = useState("")
  const [edit_flight_code, setedit_flight_code] = useState("")
  const [edit_flight_date, setedit_flight_date] = useState("")
  const [flight_number, setflight_number] = useState("")
  const [no_of_stops, setno_of_stops] = useState("")
  const [extra_stops, setextra_stops] = useState([])
  const [edit_total_amount, setedit_total_amount] = useState("")
  const [no_of_hours, setno_of_hours] = useState("2")
  const [passenger_name, setpassenger_name] = useState("")
  const [passenger_phone_number, setpassenger_phone_number] = useState("")
  const [special_note, setSpecialNote] = useState("")

  const [cc, setCC] = useState("")
  const [phone_number, setPhoneNumber] = useState("")

  // amount exceed
  const [payment_type, setPaymentType] = useState("card")
  const [balance_amount, setbalance_amount] = useState("")
  const [edit_params, setEditParams] = useState("")
  const [current_total_amount, setCurrentTotalAmount] = useState()

  // airport
  const [showflightDiv, setShowFlightDiv] = useState(0)
  const [flight_data, setFlightData] = useState([])
  const [pick_up_time, setPickUpTime] = useState("")
  const [CheckFlight, setCheckFlight] = useState(0)

  // payment details
  const [payPhone, setpayPhone] = useState("")
  const [payEmail, setpayEmail] = useState("")

  // validation
  const [formError, setFormError] = useState({ EditbookPickErr: "", EditbookDropErr: "", EditbookDateErr: "", EditbookTimeErr: "", edit_flight_etaErr: "", flight_numberErr: "", stop1: "", stop2: "", ccErr: "", phoneErr: "", passengernameErr: "" })

  // addon
  const [AddonShow, setAddonShow] = useState(0);
  const [AddonStatus, setAddonStatus] = useState(0);
  const [VehicleAddonData, setVehicleAddonData] = useState();
  const [hasRendered, setHasRendered] = useState(false);
  const [AddonData, setAddonData] = useState([]);
  const [MaxCount, setMaxCount] = useState(0);

  // max and min hours
  const [MaxHours, setMaxHours] = useState(14);
  const [MinHours, setMinHours] = useState(2);

  const AddonModelOpen = () => {
    setHasRendered(false);
    if (AddonStatus == 0) {
      setHasRendered(true);
      setAddonStatus(1)
      setTimeout(() => {
        $('#AddonDataModel').click();
      }, 1000);
    } else {
      setAddonStatus(0)
      setAddonData([])
    }
  }

  const AddonModelEdit = () => {
    setHasRendered(false);
    setTimeout(() => {
      setHasRendered(true);
      $('#AddonDataModel').click();
    }, 1000);
  }

  const getAddon = async (data) => {
    const addonData = await data;
    if (addonData != 'close') {
      setAddonData([])
      if (addonData.length > 0) {
        setAddonStatus(1)
      } else {
        setAddonStatus(0)
      }
      setAddonData(addonData)
    }
    setHasRendered(false);
  }


  // pickup
  const fromLoad = (autocomplete) => {
    setFromPlaces(autocomplete)
  }

  const onFromPlaceChanged = async () => {
    if (from_places !== null) {
      var pickLat = from_places.getPlace().geometry.location.lat()
      var pickLon = from_places.getPlace().geometry.location.lng()
      var pickAdd = from_places.getPlace().formatted_address

      setedit_pick_lat(pickLat)
      setedit_pick_lon(pickLon)
      setEditbookPick(pickAdd)
    }
  }

  // drop
  const toLoad = (autocomplete) => {
    setToPlaces(autocomplete)
  }

  const onToPlaceChanged = async () => {
    if (to_places !== null) {
      var dropLat = to_places.getPlace().geometry.location.lat()
      var dropLon = to_places.getPlace().geometry.location.lng()
      var dropAdd = to_places.getPlace().formatted_address

      setedit_drop_lat(dropLat)
      setedit_drop_lon(dropLon)
      setEditbookDrop(dropAdd)
    }
  }

  const onDateChange = (date) => {
    setEditbookDate(date)
  }

  let timeProps = {
    placeholder: 'Time'
  }

  const onTimeChange = (event) => {
    const timeValue = new Date(event);
    const formattedTime = moment(timeValue).format('hh:mm a');
    setEditbookTime(formattedTime)
  }

  const FormValidation = () => {
    let isValid = true;
    setFormError({})

    if (EditbookPick.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'EditbookPickErr': 'Enter PickUp Location' }))
    }

    if (EditbookDrop.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'EditbookDropErr': 'Enter Drop Location' }))
    }
    if (EditbookDate == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'EditbookDateErr': 'Select Date' }))
    }

    if (edit_service_type != 'airport' && EditbookTime == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'EditbookTimeErr': 'Select Time' }))
    }

    if (edit_service_type == 'airport' && edit_flight_eta == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'edit_flight_etaErr': 'Select Arrival Time' }))
    }

    if (edit_service_type == 'airport' && flight_number == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'flight_numberErr': 'Enter Flight Number' }))
    }

    if (edit_service_type == 'airport' && passenger_name.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'passengernameErr': 'Enter Passenger Name' }))
    }

    if (edit_service_type == 'airport' && cc == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'ccErr': 'Select CC' }))
    }

    if (edit_service_type == 'airport' && phone_number.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'phoneErr': 'Enter Phone Number' }))
    }

    // stop
    if (edit_service_type == 'airport' && edit_service_type == 'normal' && extra_stops.length != 0) {
      extra_stops.map((data, i) => {
        if (i == 0 && data.length == 0) {
          isValid = false;
          setFormError(prevError => ({ ...prevError, 'stop1': 'Enter Stop 1' }))
        }
        if (i == 1 && data.length == 0) {
          isValid = false;
          setFormError(prevError => ({ ...prevError, 'stop2': 'Enter Stop 2' }))
        }
      })
    }
    return isValid;
  }

  function rideTime(date, time) {
    var flightArrivalTime = new Date(date + ' ' + time)
    var calTime = 5 * 60000;
    var pick_upTime = new Date(flightArrivalTime.getTime() - calTime)

    return moment(pick_upTime).format('YYYY-MM-DD HH:mm')
  }

  // edit confirm
  const EditConfirm = async (event) => {
    event.preventDefault()
    seteditLoader(1)

    if (edit_service_type == 'airport' && CheckFlight == 0) {
      seteditLoader(0)
      toast.error('Please check flight details to procees further', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false
    }

    // validation
    if (FormValidation()) {
      let pick_up_time
      let phoNo
      if (edit_service_type == 'airport') {
        pick_up_time = edit_flight_eta
        phoNo = '+' + cc + '' + phone_number
      } else {
        pick_up_time = EditbookTime
        phoNo = passenger_phone_number
      }
      setpayPhone(phoNo)

      const inputdata = []
      var pick_up = {
        lat: edit_pick_lat,
        long: edit_pick_lon,
        stop_name: edit_pick_lon,
      };
      inputdata.push(pick_up)
      extra_stops.map((data, i) => {
        inputdata.push(data)
      })
      var drop = {
        lat: edit_drop_lat,
        lon: edit_drop_lon,
        stop_name: EditbookDrop
      }
      inputdata.push(drop)
      var extra_stop = JSON.stringify(inputdata)

      let ride_time = rideTime(moment(EditbookDate).format('YYYY-MM-DD'), EditbookTime)

      let data = {
        booking_id: booking_id,
        ride_time: ride_time,
        customer_id: edit_customer_id,
        service_type: edit_service_type,
        rider_type: edit_rider_type,
        vehicle_id: edit_vehicle_id,
        vehicle_type: edit_vehicle_type,
        pick_up: EditbookPick,
        drop_location: EditbookDrop,
        pickup_lat: edit_pick_lat,
        pickup_lon: edit_pick_lon,
        drop_lat: edit_drop_lat,
        drop_lon: edit_drop_lon,
        mode: 'ridelater',
        no_of_hours: no_of_hours,
        flight_actual_arrival_time: edit_flight_actual_arrival_time,
        flight_eta: edit_flight_eta,
        flight_carrier_code: edit_flight_carrier_code,
        flight_code: edit_flight_code,
        flight_number: flight_number.toUpperCase(),
        flight_date: edit_flight_date,
        passenger_name: passenger_name,
        passenger_phone_number: phoNo,
        date: moment(EditbookDate).format('YYYY-MM-DD'),
        pick_up_time: pick_up_time,
        no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
        extra_stops: encodeURIComponent(extra_stop),
        special_note: special_note,
        addon_services: AddonData.length > 0 ? JSON.stringify(AddonData) : '',
      };
      await ApiCall('booking/edit', data).then((data) => {

        if (data.message == "Request updated successfully") {
          toast.success('Trip updated successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.href = 'https://www.vimoservices.sg/'
          }, 3000);
        } else if (data.message == 'Total amount exceeds previous total amount') {
          seteditLoader(0)
          setbalance_amount(data.balance_amount)
          setEditParams(data.edit_params)
          setCurrentTotalAmount(data.current_total_amount)
          setTimeout(() => {
            $('#PaymentModelBtn').click()
          }, 2000);
        } else if (data.message == 'Total amount drops') {
          seteditLoader(0)
          setEditParams(data.edit_params)
          setTimeout(() => {
            $('#AmountDropbtn').click()
          }, 2000);
        } else {
          seteditLoader(0)
          toast.error(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }).catch(err => console.log(err));
    } else {
      seteditLoader(0)
    }
  }

  const inputRefs = useRef([]);
  const fromhandleChange = (ref, index) => {
    inputRefs.current[index] = ref;
  }

  const handleChange = async (place, i) => {
    const inputdata = [...extra_stops]
    if (place) {
      var stopLat = place.geometry.location.lat()
      var stopLon = place.geometry.location.lng()
      inputdata[i] = {
        lat: stopLat,
        long: stopLon,
        stop_name: place.formatted_address,
      };
    }
    setextra_stops(inputdata)
  }

  const AddStop = () => {
    const data = [...extra_stops, []]
    setextra_stops(data)
  }

  const RemoveStop = (i) => {
    const deletVal = [...extra_stops]
    deletVal.splice(i, 1)
    setextra_stops(deletVal)
  }

  const GetBookingDetails = async (id, token) => {
    let data = {
      id: id,
      access_token: token,
    };
    await ApiCall('GuestBookingDetails', data).then((data) => {

      if (data.message == "no edit") {
        let path = `../noEdit`;
        navigate(path);
      } else if (data.message == 'not found') {
        let path = `../notFound`;
        navigate(path);
      } else {
        setInitialState(1)
        setCountries(data.countries)
        setCurrency(data.currency)
        setData(data.data)
        SetDataState(data.data)
        if (data.stops.length > 0) {
          setextra_stops(data.stops)
        }
      }
    }).catch(err => console.log(err));
  }

  const SetDataState = (data) => {
    setEditbookPick(data.pick_up)
    setedit_pick_lat(data.pickup_lat)
    setedit_pick_lon(data.pickup_lon)
    setEditbookDrop(data.drop_location)
    setedit_drop_lat(data.drop_lat)
    setedit_drop_lon(data.drop_lon)
    setEditbookDate(new Date(data.ride_date))
    setEditbookTime(data.pick_up_time)
    setedit_service_type(data.service_type)
    setedit_vehicle_id(data.vehicle_id)
    setedit_vehicle_type(data.vehicle_type)
    setbooking_id(data.id)
    setedit_flight_actual_arrival_time(data.flight_actual_arrival_time != '' && data.flight_actual_arrival_time != null ? data.flight_actual_arrival_time : '')
    setedit_flight_eta(data.flight_eta != '' && data.flight_eta != null ? data.flight_eta : '')
    setedit_flight_carrier_code(data.flight_carrier_code != '' && data.flight_carrier_code != null ? data.flight_carrier_code : '')
    setedit_flight_code(data.flight_code != '' && data.flight_code != null ? data.flight_code : '')
    setedit_flight_date(data.flight_date != '' && data.flight_date != null ? data.flight_date : '')
    setflight_number(data.flight_number != '' && data.flight_number != null ? data.flight_number : '')
    setedit_total_amount(data.total_amount)
    setno_of_stops(data.no_of_stops)
    setno_of_hours(data.no_of_hours)
    setpassenger_name(data.passenger_name)
    setpassenger_phone_number(data.passenger_phone_number)
    setCC(data.passenger_phone_number.slice(1, 3))
    setPhoneNumber(data.passenger_phone_number.slice(3))
    setpayEmail(data.passenger_email)
    setSpecialNote(data.special_note)
    // addon
    if (data.addon_data != '[]') {
      setAddonShow(1)
      setAddonStatus(1)
      setMaxCount(data.vehicle_addon_max_count)
      setVehicleAddonData(data.vehicle_addon_data)
      setAddonData(data.st_addon)
    }

    if(data.addon_data == '[]' && data.service_type != 'malaysia'){
      setAddonShow(1)
      setMaxCount(data.vehicle_addon_max_count)
      setVehicleAddonData(data.vehicle_addon_data)
      setAddonData(data.st_addon)
    }
    // min and max
    setMinHours(data.charter_min_hrs)
    setMaxHours(data.charter_max_hrs)
  }

  const HrsIn = (event) => {
    let value = no_of_hours;
    if (value != MaxHours) {
      value++;
      setno_of_hours(value)
    }
  }

  const HrsOut = (event) => {
    let value = no_of_hours;
    if (value > MinHours) {
      value--
    }
    setno_of_hours(value)
  }

  const inputChange = (event) => {
    let { name, value } = event.target;
    if (name == 'payment_type') {
      setPaymentType(value)
    } else if (name == 'flight_eta') {
      setedit_flight_eta(value)
    } else if (name == 'flight_number') {
      setflight_number(value)
    } else if (name == 'EditPassengerName') {
      setpassenger_name(value)
    } else if (name == 'passenger_phone_code') {
      setCC(value)
    } else if (name == 'passenger_phone_number') {
      setPhoneNumber(value)
    } else if (name == 'comment') {
      setSpecialNote(value)
    }
  }

  const refreshPage = () => {
    window.location.reload(false);
  }

  const getSuccess = async (token) => {
    const tokenMessage = await token;
    if (tokenMessage == 'sucess') {

      if (payment_type == 'card') {
        let data = {
          booking_id: booking_id,
          edit_params: edit_params,
          balance_amount: balance_amount,
          payment_type: payment_type
        }
        await ApiCall('confirmGuestBooking', data).then((data) => {

          if (data.message == "success") {
            SuccessRedirect()
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }).catch(err => console.log(err));
      } else {
        SuccessRedirect()
      }

    } else {
      toast.error('Payment Failed, Please Try Again....', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => { refreshPage() }, 5000)
    }
  }

  const SuccessRedirect = () => {
    toast.success('Payment succeeded. Trip updated successfully', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    setTimeout(() => {
      window.location.href = 'https://www.vimoservices.sg/'
    }, 3000);
  }

  const checkFlight = async () => {
    if (EditbookDate == "") {
      toast.error('Select date', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false
    }

    if (flight_number.trim() == "") {
      toast.error('Enter flight number', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false
    }

    let data = {
      date: moment(EditbookDate).format('YYYY-MM-DD'),
      flight_number: flight_number,
    }
    await ApiCall('getScheduledFlightDetails', data).then((data) => {
      if (data.message === "Flight details listed successfully") {
        setFlightData(data.result[0])

        var dataFgt = data.result[0]
        setedit_flight_actual_arrival_time(dataFgt.FlightArrivalTime)
        setedit_flight_carrier_code(dataFgt.carrierFsCode)
        setedit_flight_code(dataFgt.flightNumber)
        setedit_flight_date(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
        setShowFlightDiv(1)
        setCheckFlight(1)

        // pick_up_time calculation
        var flightArrivalTime = new Date(dataFgt.FlightArrivalTime)
        var arrivalTime = parseInt(edit_flight_eta, 10)
        var calTime = arrivalTime * 60000
        var pick_upTime = new Date(flightArrivalTime.getTime() + calTime)
        setPickUpTime(pick_upTime.toTimeString().slice(0, 8))
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false
      }
    }).catch(err => console.log(err));
  }

  const AmountDrop = async () => {
    let data = {
      booking_id: booking_id,
      edit_params: edit_params,
    }
    await ApiCall('confirmGuestBooking', data).then((data) => {

      if (data.message == "success") {
        toast.success('Trip updated successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = 'https://www.vimoservices.sg/'
        }, 3000);
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  const Appsetting = async () => {
    let data = {}
    await ApiCall('GuestAppSetting', data).then((data) => {
      if (data.success == "success") {
        setPayNowStatus(data.guest_paynow_status)
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    Appsetting()
    const searchParams = new URLSearchParams(window.location.search);
    const booking_id = searchParams.get('id');
    const access_token = searchParams.get('access_token');
    GetBookingDetails(booking_id, access_token)
  }, [])

  useEffect(() => {
    setCheckFlight(0)
    setShowFlightDiv(0)
  }, [flight_number])

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* edit booking */}
      {initial_state == 1 ? <div>
        <LoadScript libraries={libraries} googleMapsApiKey={ApiKey.google_map_key}>
          <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
              <div className="container-fluid">
                <a className="navbar-brand head_nav" href="#">
                  <img src={logo_big_new} alt="Bootstrap" className="nav_image" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </nav>
            <section id="booking-details">
              <div className="row bk-row">
                <div className="col-12 col-md-6 order-2 order-md-1" id="bk-bg1">
                  <div className="container">
                    <h3>Edit Booking</h3>
                    <input type="hidden" id="currency_symbol" value={currency.symbol} />
                    <form id="editFromSubmit" method="post" action="" autoComplete="off">
                      <div className="form-group mb-3">
                        <label className="form-label">Pickup Location</label>
                        <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged} options={AutocompleteRestrict}>
                          <input type="text" className="form-control" name="EditbookPick" id="EditbookPick" placeholder="Pickup Location" aria-label="Pickup Location" defaultValue={EditbookPick} />
                        </Autocomplete>
                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.EditbookPickErr}</span>
                      </div>

                      {extra_stops.map((data, i) => {
                        return (
                          <div className='align-items-center'>
                            <div className="row mb-3">
                              <div className="col-lg-11 col-10">
                                <Autocomplete onPlaceChanged={() => handleChange(inputRefs.current[i]?.getPlace(), i)} onLoad={(autocomplete) => fromhandleChange(autocomplete, i)} options={AutocompleteRestrict}>
                                  <input type="text" className="form-control rounded-pill" placeholder={`Stop ${i + 1}`} defaultValue={data.stop_name} />
                                </Autocomplete>
                                <span className="error-field text-dark mb-3" style={{ marginLeft: "0.5rem" }}>{formError['stop' + (i + 1)]}</span>
                              </div>
                              <div className="col-lg-1 col-2">
                                <i className="fa fa-times-circle" style={{ color: 'white' }} onClick={() => RemoveStop(i)}></i>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      {data.service_type == 'normal' || data.service_type == 'airport' ? <div className="d-flex justify-content-end mb-2">
                        <button type="button" id="add_stop" className="btn btn-sm back_button" disabled={data.no_of_stops == 2 ? true : false} onClick={AddStop}>Add Stop</button>
                      </div> : <></>}

                      <div className="form-group mb-2">
                        <label className="form-label">Drop Location</label>
                        <Autocomplete onLoad={toLoad} onPlaceChanged={onToPlaceChanged} options={AutocompleteRestrict}>
                          <input type="text" className="form-control" name="EditbookDrop" id="EditbookDrop" placeholder="Drop Location" aria-label="Drop Location" defaultValue={EditbookDrop} />
                        </Autocomplete>
                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.EditbookDropErr}</span>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label">Vehicle Type</label>
                        <input type="text" className="form-control" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" aria-label="Corportate Name" value={data.vehicle_type} readOnly />
                      </div>

                      <div className={AddonShow == 1 ? 'col-lg-6 col-12' : 'col-lg-6 col-12 d-none'}>
                        {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                        <div class="form-check d-flex justify-content-between">
                          <div className=''>
                            <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpen} checked={AddonData != '' ? true : false} />
                            <label class="form-check-label">
                              Add-on Services
                            </label>
                            <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon"></i>
                          </div>
                          <p className={AddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEdit}>Edit</p>

                          <ReactTooltip id="tooltip-addon" place="top">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {AddonData.length > 0 ? AddonData.map((item, key) => {
                                return (
                                  <span>{item.addon_name} - {item.count}</span>
                                )
                              }) : <><p>No add-on selected</p></>}
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>

                      {data.service_type == 'normal' || data.service_type == 'charter' || data.service_type == 'malaysia' ? <>
                        <div className="form-group mb-2">
                          <div className="row">
                            <div className="col">
                              <div className="form-group col-xs-6">
                                <label className="form-label">Booking Date</label>
                                <div className="inner-addon right-addon">
                                  <i className="glyphicon glyphicon-search fa fa-calendar" style={{ color: '#cb2734' }}></i>
                                  <DatePicker
                                    selected={EditbookDate}
                                    onChange={onDateChange}
                                    dateFormat='yyyy-MM-dd'
                                    minDate={new Date}
                                    className='form-control'
                                    placeholderText='Booking Date'
                                  />
                                </div>
                              </div>
                              <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.EditbookDateErr}</span>
                            </div>
                            <div className="col">
                              <label className="form-label">Booking Time</label>
                              <div className="inner-addon right-addon">
                                <i className="glyphicon glyphicon-search fa fa-clock" style={{ color: '#cb2734' }}></i>
                                <Datetime dateFormat={false}
                                  inputProps={timeProps}
                                  onChange={onTimeChange}
                                  timeConstraints={timeConstraints}
                                  value={EditbookTime}
                                />
                              </div>
                              <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.EditbookTimeErr}</span>
                            </div>
                          </div>
                        </div>
                      </> : <></>}

                      {data.service_type == 'charter' ? <>
                        <div className="your-trip-details pb-3">
                          <div className="row pt-3 align-items-center">
                            <div className="col-lg-12">
                              <label className="form-label no_hours" style={{ color: 'white' }}>No of Hours({MinHours}-{MaxHours}hrs)</label>
                              <div className="hourbutton">
                                <span style={{ cursor: 'pointer', color: '#cb2734' }} className='nohourEdit-decrement-btn' onClick={HrsOut}>&nbsp; - &nbsp;</span>
                                <span className="number_paddE" id="hour-input-edit" style={{ color: '#cb2734' }}>{no_of_hours}</span>
                                <span style={{ cursor: 'pointer', color: '#cb2734' }} className='nohourEdit-increment-btn' onClick={HrsIn}>&nbsp;+&nbsp;</span>
                                <input type="hidden" name="no_of_hours" id="no_of_hours_edit" value={data.no_of_hours} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </> : <></>}

                      {data.service_type == 'airport' ? <>
                        <div class="row mb-2">
                          <div class="col-6 col-lg-6">
                            <label for="bookDate" class="form-label" style={{ color: 'white' }}>Select Pickup Date</label>
                            <div class="d-flex align-items-center gap-3">
                              <div class="inner-addon right-addon pass-detail">
                                <i class="glyphicon glyphicon-search fa fa-calendar" style={{ color: '#cb2734' }}></i>
                                <DatePicker
                                  selected={EditbookDate}
                                  onChange={onDateChange}
                                  dateFormat='yyyy-MM-dd'
                                  minDate={new Date}
                                  className='form-control'
                                  placeholderText='Booking Date'
                                />
                              </div>
                            </div>
                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.EditbookDateErr}</span>
                          </div>
                          <div class="col-6 col-lg-6">
                            <label for="bookDate" class="form-label" sstyle={{ color: 'white' }}>Select Arrival Time</label>
                            <div class="d-flex align-items-center gap-3">
                              <select class="form-select s-group" aria-label="Default select example" style={{ color: '#cb2734' }} id="driver_arrival_time" name="flight_eta" onChange={inputChange}>
                                <option value="">Choose Driver Arrival Time</option>
                                <option value="10 mins" selected={edit_flight_eta == '10 mins' ? true : false}>10 MINS</option>
                                <option value="20 mins" selected={edit_flight_eta == '20 mins' ? true : false}>20 MINS</option>
                                <option value="30 mins" selected={edit_flight_eta == '30 mins' ? true : false}>30 MINS</option>
                                <option value="40 mins" selected={edit_flight_eta == '40 mins' ? true : false}>40 MINS</option>
                                <option value="50 mins" selected={edit_flight_eta == '50 mins' ? true : false}>50 MINS</option>
                                <option value="60 mins" selected={edit_flight_eta == '60 mins' ? true : false}>60 MINS</option>
                                <option value="70 mins" selected={edit_flight_eta == '70 mins' ? true : false}>70 MINS</option>
                                <option value="80 mins" selected={edit_flight_eta == '80 mins' ? true : false}>80 MINS</option>
                                <option value="90 mins" selected={edit_flight_eta == '90 mins' ? true : false}>90 MINS</option>
                                <option value="100 mins" selected={edit_flight_eta == '100 mins' ? true : false}>100MINS</option>
                                <option value="110 mins" selected={edit_flight_eta == '110 mins' ? true : false}>110MINS</option>
                                <option value="120 mins" selected={edit_flight_eta == '120 mins' ? true : false}>120MINS</option>
                              </select>
                              <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.edit_flight_etaErr}</span>
                            </div>
                          </div>
                        </div>

                        <div class="row align-items-center mb-2">
                          <div class="col-12 col-lg-6">
                            <div class="form-group f-group mb-2">
                              <label for="bookPick" class="form-label">Please Enter your FlightNumber</label>
                              <input type="text" class="form-control" id="flight_number" name="flight_number" placeholder="your Flight Number" aria-label="your Flight Number" defaultValue={flight_number} onChange={inputChange} />
                              <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.flight_numberErr}</span>
                            </div>
                          </div>
                          <div class="col-6 col-lg-6  pt-4">
                            <button type="button" class="btn btn-sm" id="chck-flight-dtls" onClick={checkFlight}>Check Flight Details</button>
                          </div>
                        </div>

                        {showflightDiv == 1 ? <>
                          <div class="row pt-3 ps-2 pe-2 justify-content-center" id="flight_data">
                            <div class="col-12 col-lg-12">
                              <div class="row flight-details align-items-center">
                                <div class="col-2 col-lg-1">
                                  <i class="fa fa-plane" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                </div>
                                <div class="col-8 col-lg-10">
                                  <div>
                                    <span style={{ color: '#cb2734' }}>Flight Number</span>:&nbsp;<span id="f_number" style={{ color: '#cb2734' }}>{flight_number}</span><br />
                                    <span style={{ color: '#cb2734' }}>Scheduled Date/Time</span>:&nbsp;<span id="f_date_time" style={{ color: '#cb2734' }}>{flight_data.arrivalTime}</span><br />
                                  </div>
                                </div>
                                <div class="col-2 col-lg-1">
                                  <i class="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </> : <></>}

                        <div class="passenger-dtls pt-3">
                          <span class="headingss" style={{ color: 'white' }}>Passenger Details</span>
                          <input type="hidden" name="no_of_adults" id="no_of_adults" value="{{$trip->no_of_adults}}" />
                          <input type="hidden" name="luggage" id="luggage" value="{{$trip->luggage}}" />
                          <input type="hidden" name="wheel_chair" id="wheel_chair" value="{{$trip->wheel_chair}}" />
                          <div class="row pt-2 pb-3">
                            <div class="col-4 col-lg-4">
                              <span class="no_of" style={{ color: 'white' }}>No of passengers</span>
                              <div class="buttons">
                                <span style={{ color: '#cb2734' }} class='airport-decrement-btn'> &nbsp;-&nbsp;</span>
                                <span class="number_paddE" id="passenger-input">{data.no_of_adults}</span>
                                <span style={{ color: '#cb2734' }} class='airport-increment-btn'> &nbsp;+&nbsp;</span>
                              </div>
                            </div>
                            <div class="col-4 col-lg-4">
                              <span class="no_of" style={{ color: 'white' }}>No of Wheelchairs</span>
                              <div class="buttons">
                                <span style={{ color: '#cb2734' }} class='airport-decrement-btn'> &nbsp;-&nbsp;</span>
                                <span class="number_paddE" id="wheelchair-input">{data.wheel_chair}</span>
                                <span style={{ color: '#cb2734' }} class='airport-increment-btn'> &nbsp;+&nbsp;</span>
                              </div>
                            </div>
                            <div class="col-4 col-lg-4">
                              <span class="no_of" style={{ color: 'white' }}>No of Luggages</span>
                              <div class="buttons">
                                <span style={{ color: '#cb2734' }} class='airport-decrement-btn'> &nbsp;-&nbsp;</span>
                                <span class="number_paddE" id="luggage-input">{data.luggage}</span>
                                <span style={{ color: '#cb2734' }} class='airport-increment-btn'> &nbsp;+&nbsp;</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group mb-2">
                          <label for="bookPick" class="form-label">Main Passenger Name</label>
                          <input type="text" class="form-control" name="EditPassengerName" id="MainName" placeholder="Main Passenger Name" defaultValue={passenger_name} onChange={inputChange} />
                          <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.passengernameErr}</span>
                        </div>

                        <div class="row pt-3">
                          <label for="contact" class="form-label" style={{ color: 'white' }}>
                            Contact No</label>
                          <div class="col-12 col-xl-4">
                            <select class="form-select group-2" aria-label="Default select example" id="pho_code" name="passenger_phone_code" onChange={inputChange}>
                              {countries.map((data, i) => {
                                return <option key={i} value={data.phonecode} selected={data.phonecode == cc ? true : false}>+{data.phonecode}-{data.sortname}</option>
                              })}
                            </select>
                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.ccErr}</span>
                          </div>
                          <div class="col-12 col-xl-8 phoneTop">
                            <input type="text" class="form-control " id="p_number" name="passenger_phone_number" placeholder="Enter your Number" aria-label="Enter your Number" defaultValue={phone_number} />
                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.phoneErr}</span>
                          </div>
                        </div>
                      </> : <></>}

                      <div className="form-group mb-3 comment">
                        <textarea className="form-control" rows="1" placeholder="Comments if anything" name='comment' id="comment" onChange={inputChange}>{special_note}</textarea>
                      </div>

                      <hr />

                      <div>
                        <h4 id="ch_bk">Choose Your Booking Type</h4>
                        <div className="row pt-3">
                          <div
                            className={data.service_type == 'normal' && data.trip_type == 3 ? 'col-6 col-md-3 d-flex justify-content-center align-items-center bk-hover' : 'col-6 col-md-3 d-flex justify-content-center align-items-center'}>
                            <img src={route} className="travel_img" />
                            <span>Scheduled</span>
                          </div>
                          <div
                            className={data.service_type == 'airport' ? 'col-6 col-md-3 d-flex justify-content-center align-items-center bk-hover' : 'col-6 col-md-3 d-flex justify-content-center align-items-center'}>
                            <img src={airport} className="travel_img" />
                            <span>Airport</span>
                          </div>
                          <div
                            className={data.service_type == 'charter' ? 'col-6 col-md-3 d-flex justify-content-center align-items-center bk-hover' : 'col-6 col-md-3 d-flex justify-content-center align-items-center'}>
                            <img src={aeroplane} className="travel_img" />
                            <span>Charter</span>
                          </div>
                          <div
                            className={data.service_type == 'malaysia' ? 'col-6 col-md-3 d-flex justify-content-center align-items-center bk-hover' : 'col-6 col-md-3 d-flex justify-content-center align-items-center'}>
                            <img src={two_arrow} className="travel_img" />
                            <span>&nbsp;&nbsp;Singapore<br />&nbsp;&nbsp;Malaysia</span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <label><b>Trip amount deducted {currency.symbol} {parseFloat(data.total_amount).toFixed(2)}</b></label>
                        </div>
                        <div className='col-12'>
                          {data.service_type == 'airport' ? <label style={{ textAlign: 'center' }} id="flight_label"><b>Note:Please check flight details to procees further</b></label> : <></>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-end">
                          {editLoader == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                            height="40"
                            width="40"
                            radius="9"
                            color="#ff8901"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          /></div> : <button id="edit_confirm" type="submit" className="book-now-btn" onClick={EditConfirm}>Confirm</button>}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12 col-md-6 order-1 order-md-2 d-flex align-items-center" id="bk-bg2">
                  <div className="container p-3">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-11 bk-si">
                        <h3 className="text-center" id="sm-size">Booking Details</h3>

                        <div className="mb-4 side-color">
                          <p>From Location</p>
                          <h6 id="pickValue">{EditbookPick}</h6>
                        </div>

                        {extra_stops.length > 0 ? extra_stops.map((data, i) => {
                          return (
                            <div className="mb-4 side-color">
                              <p>Stop {i + 1}</p>
                              <h6 className="m-0" id="dropValue">{data.stop_name}</h6>
                            </div>
                          )
                        }) : <></>}

                        <div className="mb-4 side-color">
                          <p>To Location</p>
                          <h6 className="m-0" id="dropValue">{EditbookDrop}</h6>
                        </div>

                        {data.service_type == 'airport' ? <>
                          <div className="mb-4">
                            <div className="row">
                              <div className="col-3 col-md-2 d-flex justify-content-start align-items-center check_data">
                                <i className="fa fa-users side-color" aria-hidden="true"></i>
                                <span className="icon_span side-color" id="passengercount">{data.no_of_adults}</span>
                              </div>
                              <div className="col-3 col-md-2 d-flex justify-content-start align-items-center check_data">
                                <i className="fa fa-suitcase side-color" aria-hidden="true"></i>
                                <span className="icon_span side-color" id="wheelchaircount">{data.wheel_chair}</span>
                              </div>
                              <div className="col-3 col-md-2 d-flex justify-content-start align-items-center">
                                <i className="fa fa-wheelchair side-color" aria-hidden="true"></i>
                                <span className="icon_span side-color" id="luaggecount">{data.luggage}</span>
                              </div>
                              <div className="col-3 col-md-6"></div>
                            </div>
                          </div></> : <></>}

                        <div className="mb-4 d-flex justify-content-start align-items-center">
                          <img src={calendar} className="side-img-col" style={{ width: '5%' }} />
                          {data.service_type == 'airport' ? <><span style={{ paddingLeft: '20px' }} className="side-color" id="DateTimeValue">{moment(EditbookDate).format('YYYY-MM-DD')}</span></> : <><span style={{ paddingLeft: '20px' }} className="side-color" id="DateTimeValue">{moment(EditbookDate).format('YYYY-MM-DD')} {EditbookTime}</span></>}
                        </div>

                        <div className="mb-4 d-flex justify-content-start align-items-center">
                          <img src={sedan} style={{ width: '7%' }} className="side-img-col" />
                          <span style={{ paddingLeft: '20px' }} className="side-color">{data.vehicle_type}</span>
                        </div>

                        {data.service_type == 'charter' ? <>
                          <div className="mb-4 d-flex justify-content-start align-items-center">
                            <img src={clock} style={{ width: '5%' }} className="side-img-col" />
                            <span style={{ paddingLeft: '20px' }} className="side-color">{data.no_of_hours} hour(s)</span>
                          </div></> : <></>}

                        {AddonData != [] ? 
                          <div className="mb-4 side-color">
                            <p>Add-on Services</p>
                            {AddonData.map((data,index)=>{
                              return (<p>{data.addon_name} - {data.count}</p>)
                            })}
                          </div>
                        : <></>}

                        <hr style={{ color: '#cb2734' }} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer className="site-footer">
              <p className="text-center pt-2"><a href="#">vimoservices.com&nbsp;</a>copyright&copy;&nbsp;2023</p>
            </footer>
          </>
        </LoadScript>
      </div> : <div className='container h-100'>
        <div className='d-flex justify-content-md-center align-items-center vh-100'>
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#ca2733"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          <br />
        </div>
      </div>}

      {/* payment type */}
      <>
        <div className='d-none'>
          <input data-bs-toggle="modal" data-bs-target="#GuestPaymentModel" data-bs-backdrop="false" id="PaymentModelBtn" />
        </div>
        <div className="modal fade" id="GuestPaymentModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Payment Confirmation</h5>
                <button type="button" className="btn-close" id="GupayClose" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <label id="guest_total_amount_confirm">Total amount exceeds previous total amount.To proceed further you need to pay - SGD {balance_amount}</label>
                  <hr />
                  <p className="text-center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Type</p>
                  <div className='d-grid justify-content-center'>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="payment_type" id="payment_type" value="card" onChange={inputChange} checked={payment_type === 'card'} />
                      <label className="form-check-label">
                        &nbsp;Card
                      </label>
                    </div>
                    {PayNowStatus == 1 ?
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="payment_type" id="payment_type" value="paynow" onChange={inputChange} checked={payment_type === 'paynow'} />
                        <label className="form-check-label">
                          &nbsp;Paynow
                        </label>
                      </div> : <></>
                    }
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {payment_type == 'card' ? <div className='text-end'>
                  <button type="submit" className="btn rounded-pill cartBtn" data-bs-toggle="modal" data-bs-target="#CardModule">Confirm</button>
                  <button type="button" className="btn rounded-pill cartBtn ms-2" data-bs-dismiss="modal">Cancel</button>
                </div> : <div className='d-flex justify-content-end'>
                  <Elements stripe={stripePromise}>
                    <EditPaynow amount={balance_amount} email={payEmail} phoneNumber={payPhone} editParams={edit_params} bookingId={booking_id} getSuccess={getSuccess} />
                  </Elements>
                  <button type="button" className="btn rounded-pill cartBtn ms-2" data-bs-dismiss="modal">Cancel</button>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </>

      {/* model for amount drop trip update confirm */}
      <>
        <div className='d-none'>
          <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#AmountDrop" id="AmountDropbtn" value='' />
        </div>
        <div className="modal fade" id="AmountDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Booking Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className='modal-body'>
                <p>The edited ride costs lesser and there is no any form of refund on the unused credits. Are you sure want to proceed further?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                <button type="button" className="btn btn-danger" onClick={AmountDrop}>Yes</button>
              </div>
            </div>
          </div>
        </div>

      </>

      <Elements stripe={stripePromise}>
        <CardModule id="CardModule" title="Confirm Your Payment" amount={balance_amount} email={payEmail} phoneNumber={payPhone} getSuccess={getSuccess} />
      </Elements>

      {/* paynow alert model */}
      <>
        {/* model for confirmation */}
        <div className='d-none'>
          <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#confirmMessage" data-bs-backdrop="false" id="messageConfirm" />
        </div>
        <div class="modal fade" id="confirmMessage" aria-labelledby="confirmMessage" aria-hidden="true" data-bs-backdrop="false">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header megHeader">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Vimo Services</h1>
                <button type="button" class="btn-close d-none" data-bs-dismiss="modal" id="confirmClose" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className='container'>
                  <div className='d-flex align-item-center justify-content-center'>
                    <img className="" src={alertImg} width={250} height={250} />
                  </div>
                  <h5 className='alertText text-center p-2'>DO NOT GO AWAY from this screen and do not close the window. It may takes up to 1 minute for payment confirmation. And you shall receive sms and email as confirmation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* add-on model button */}
      <div className='d-none'>
        <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#AddonData" data-bs-backdrop="false" id="AddonDataModel" />
      </div>
      {!hasRendered ? <></> : <EditAddonModel AddonData={VehicleAddonData} SelectAddonData={AddonData} MaxCountAddon={MaxCount} getAddon={getAddon} />}
    </div>
  )
}

export default EditBooking